import Helmet from 'react-helmet';
import { Heading } from 'rebass';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../components/styles/Container';
import Text from '../components/styles/Text';

import React, { Component } from 'react';

class NotFoundPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <Helmet>
          <title>Page Not Found!</title>
        </Helmet>
        <Page>
          <Container py={7} width="500px">
            <Img py={3} fluid={data.file.childImageSharp.fluid} />
            <Heading is="h1" mb={4} f={6} center color="gray9">
              Oops!
            </Heading>
            <Text f={4} center lineHeight="normal" color="gray8">
              We couldn&apos;t find what you were looking for. But here's a cool
              car to check out in the meantime. Head back and try again.
            </Text>
          </Container>
        </Page>
      </div>
    );
  }
}

export default NotFoundPage;

export const query = graphql`
  query FourQuery {
    file(relativePath: { eq: "images/red_t.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
